import {toAbsoluteUrl} from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      {/* THEME: LOGO */}
      <img src={toAbsoluteUrl('/media/saas/investopia.svg')} alt='INVESTOPIA' />
      <span>Loading ...</span>
    </div>
  )
}
